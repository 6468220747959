/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
// styles
import './Button.scss';

const Button = ({ type, onClick, children, className, tabIndex }) => {
  return (
    <button type={type} onClick={onClick} className={className} tabIndex={tabIndex}>
      {children}
    </button>
  );
};

Button.propTypes = {
  tabIndex: PropTypes.number,
  className: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Button.defaultProps = {
  tabIndex: 1,
  className: '',
  children: undefined,
  type: 'button',
  onClick: () => {},
};

export default Button;
