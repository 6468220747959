import localforage from 'localforage';

const retrieveData = async () => {
  const keys = await localforage.keys();
  const data = await Promise.all(
    keys.map(async key => {
      const item = await localforage.getItem(key);
      return { ...item };
    })
  );
  return data;
};

export default retrieveData;
