import React from 'react';
import PropTypes from 'prop-types';
// icons
import { ReactComponent as UserPic } from 'icons/list_of_users.svg';
// styles
import './Placeholder.scss';

const Placeholder = ({ getInputProps, getRootProps, error, touched, imagefile }) => (
  <div {...getRootProps()} className={`placeholder-preview ${error && touched ? 'error' : ''}`}>
    <input {...getInputProps()} />
    <span className="error-text img">{error}</span>
    {imagefile.size !== 0 && imagefile.file ? (
      <img
        src={imagefile.preview}
        alt="profile pic"
        className={`profilePic ${imagefile.size > 1000000 ? ' pic-red' : ''}`}
      />
    ) : (
      <div className="profilePic">
        <UserPic alt="profile pic" className="emptyPic" />
      </div>
    )}
    <button type="button" className="btn-small">
      + add profile picture
    </button>
  </div>
);

Placeholder.propTypes = {
  error: PropTypes.string,
  getInputProps: PropTypes.func.isRequired,
  getRootProps: PropTypes.func.isRequired,
  touched: PropTypes.bool,
  imagefile: PropTypes.shape({
    file: PropTypes.shape({}),
    size: PropTypes.number,
    preview: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

Placeholder.defaultProps = {
  error: undefined,
  touched: false,
};

export default Placeholder;
