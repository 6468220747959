import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as formReducer } from 'redux-form';
// reducers
import tabsVariables from 'store/modules/tabsVariables';
import dataStored from 'store/modules/dataStored';
import currentUser from 'store/modules/currentUser';

const rootReducer = combineReducers({
  form: formReducer,
  dataStored,
  tabsVariables,
  currentUser,
});

export const sagaMiddleware = createSagaMiddleware();
export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
