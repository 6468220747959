export const phoneValid = value => (value && value.includes('_') ? 'Invalid phone number' : undefined);
export const phonesValid = value => {
  let result = true;
  if (value && Array.isArray(value)) {
    value.forEach(e => {
      result = !(result && phoneValid(e));
    });
  }
  return !result ? '' : undefined;
};
export const phoneRequired = value => (!value || value === '' ? 'Required' : undefined);
export const imgLessThanMb = value => (value && value.size > 1000000 ? 'Image size shouldnt exceed 1 MB' : undefined);
export const required = value => (value || typeof value === 'number' ? undefined : 'Required');
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const maxLength15 = maxLength(15);
export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;
export const minLength3 = minLength(3);
export const number = value => (value && Number.isNaN(Number(value)) ? 'Must be a number' : undefined);
export const minValue = min => value => (value && value < min ? `Must be at least ${min}` : undefined);
export const minValue13 = minValue(13);
export const over18 = value =>
  new Date() - Date.parse(value) < 31536000000 * 18 ? 'Should be at least 18 years old' : undefined;
export const radioRequired = (value, allValues) => (!(value || allValues.female) ? 'Required' : undefined);
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined;
export const tooYoung = value => (value && value < 13 ? 'You do not meet the minimum age requirement!' : undefined);
export const aol = value =>
  value && /.+@aol\.com/.test(value) ? 'Really? You still use AOL for your email?' : undefined;
export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value) ? 'Only alphanumeric characters' : undefined;
export const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value) ? 'Invalid phone number, must be 10 digits' : undefined;
export const passwordsMustMatch = (value, allValues) =>
  value !== allValues.password ? 'Passwords do not match' : undefined;
export const confirmPassword = (pass1, pass2) => (pass1 !== pass2 ? 'Passwords dont match' : undefined);
