import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';

import App from 'pages';
import rootSaga from 'store/sagas/index';
import { store, sagaMiddleware } from './varibles';

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
