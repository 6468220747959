import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'react-linkify';
// icons
import { ReactComponent as Edit } from 'icons/Edit.svg';
// styles
import './Section.scss';

const Section = props => {
  const { title, data, onClick } = props;
  return (
    <div className="edit-item rows">
      <div className="rows section">
        <h5>{title}</h5>
        <button type="button" className="btn-small" onClick={onClick} onKeyPress={() => {}}>
          <Edit alt={`edit ${title.toLowerCase()} data`} className="edit-button" />
        </button>
      </div>

      <div className="columns">
        {Object.values(data).map(({ value, label }, index) => (
          <div className="rows" key={`${title}-${label}-${index + 1}`}>
            <p>{`${label}:`}</p>
            {Array.isArray(value) ? (
              <div className="hobbies-list">
                {value.map((val, num) => (
                  <span key={`hobby-${num + 1}`}>{val}</span>
                ))}
              </div>
            ) : (
              <span>
                {label === 'Email' || label === 'Github' || label === 'Facebook' ? <Linkify>{value}</Linkify> : value}
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Section;
