import React from 'react';
import { Field, reduxForm, getFormSyncErrors, change } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// redux-actions
import { selectTabAction } from 'store/modules/tabsVariables';
import { userNextStepAction } from 'store/modules/currentUser';
// validation funcs
import { required, phoneValid } from 'helpers/validate';
// constants
import { languagesList as options } from 'helpers/variables';
// components
import MySelect from 'components/Forms/MySelect/MySelect';
import Phone from 'components/Forms/Phone/Phone';
import RenderField from 'components/Forms/RenderField/RenderField';
import Button from 'components/Button/Button';
import Buttons from '../components/Buttons/Buttons';
// styles
import './Contacts.scss';

const getPhoneCount = initialValues => {
  if (initialValues) {
    if (initialValues.phone3) return 3;
    if (initialValues.phone2) return 2;
  }
  return 1;
};
const getPhoneArray = initialValues => {
  const phones = [];
  if (initialValues) {
    if (initialValues.phone1) phones.push(initialValues.phone1);
    else phones.push('');
    if (initialValues.phone2) phones.push(initialValues.phone2);
    if (initialValues.phone3) phones.push(initialValues.phone3);
  }
  return phones;
};

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numbers: getPhoneCount(props.initialValues),
      value: getPhoneArray(props.initialValues),
    };
  }

  addPhone = () => {
    const { value } = this.state;
    const newValue = value;
    newValue.push('');
    this.setState(prevState => ({ numbers: prevState.numbers + 1, value: newValue }));
  };

  delPhone = i => {
    const { value, numbers } = this.state;
    const { dispatch } = this.props;
    const newValue = value;
    newValue.splice(i, 1);
    this.setState({ numbers: numbers - 1, value: newValue }, () => {
      value.forEach((e, index) => dispatch(change('profile', `phone${index + 1}`, e)));
      const a = [...Array(3 - value.length)];
      a.forEach((_, index) => {
        dispatch(change('profile', `phone${3 - index}`, ''));
      });
    });
  };

  onChangeHandler = (newValue, i) => {
    const { value } = this.state;
    const copiedValue = value;
    copiedValue[i] = newValue;
    this.setState({ value: copiedValue }, () => {});
  };

  render() {
    const { isEditing, formErrors, valid, touch, form, nextStep, initialValues, onSubmit } = this.props;
    const { numbers, value } = this.state;
    return (
      <form className="contactsForm" onSubmit={e => onSubmit(e, valid, nextStep, touch, formErrors)}>
        <div className="columns">
          <Field
            label="Company"
            htmlFor="company"
            name="company"
            component={RenderField}
            type="text"
            validate={required}
            isRequired
            id="company"
          />
          <Field label="Gihub Link" htmlFor="github" name="github" component={RenderField} type="text" id="github" />
          <Field
            label="Facebook Link"
            htmlFor="facebook"
            name="facebook"
            component={RenderField}
            type="text"
            id="facebook"
          />
          <Field
            label="Language"
            htmlFor="language"
            name="language"
            component={MySelect}
            validate={required}
            values={initialValues && initialValues.language ? form.values.language : null}
            id="language"
            options={options}
            isRequired
          />
        </div>
        <div className="columns">
          <Field label="Fax" htmlFor="fax" name="fax" component={Phone} type="fax" validate={phoneValid} id="fax" />
          <div className="phones">
            {[...Array(numbers)].map((_, i) => (
              <div key={`phone${i + 1}`} className="item-phone">
                <Field
                  label={`Phone #${i + 1}`}
                  htmlFor={`phone${i + 1}`}
                  name={`phone${i + 1}`}
                  component={Phone}
                  type={`phone${i + 1}`}
                  onChange={(__, newValue) => this.onChangeHandler(newValue, i)}
                  validate={phoneValid}
                  id={`phone${i + 1}`}
                  value={value[i]}
                />
                {numbers !== 1 && (
                  <Button className="btn-small del-phone" onClick={() => this.delPhone(i)}>
                    &mdash;
                  </Button>
                )}
              </div>
            ))}
          </div>
          {numbers < 3 && (
            <Button className="btn-small add-phone" onClick={this.addPhone}>
              + add phone number
            </Button>
          )}
          <Buttons isEditing={isEditing} />
        </div>
      </form>
    );
  }
}

Form.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  formErrors: PropTypes.shape({}).isRequired,
  valid: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  form: PropTypes.shape({
    values: PropTypes.shape({
      language: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    }),
  }).isRequired,
  nextStep: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    language: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
  }),
};

Form.defaultProps = {
  initialValues: {},
};

const mapStateToProps = state => ({
  formErrors: getFormSyncErrors('profile')(state),
  form: state.form.profile,
  activeTab: state.tabsVariables.activeTab,
  data: state.dataStored.users,
});

const mapDispatchToProps = {
  onClick: selectTabAction,
  nextStep: userNextStepAction,
};

const ContactsForm = reduxForm({
  form: 'profile',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form)
);

export default ContactsForm;
