import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { timeAgo } from 'helpers/helpers';
// redux-actions
import { deleteUserAction } from 'store/modules/dataStored';
// components
import Table from 'components/Table/Table';
import Pagination from 'components/Pagination/Pagination';
import Button from 'components/Button/Button';
// icons
import user from 'icons/list_of_users.svg';
// styles
import './list.scss';

const getData = data => {
  return Object.values(data).map(item => [
    {
      username: item.username,
      firstName: item.firstName,
      lastName: item.lastName,
      picture: Object.prototype.hasOwnProperty.call(item, 'profilePicture')
        ? URL.createObjectURL(item.profilePicture.file)
        : user,
    },
    item.company,
    item.phone1 || item.phone2 || item.phone3 || item.email,
    timeAgo(item.lastUpdate),
  ]);
};

const sortData = (value, data) => {
  const sortedData = {};
  Object.values(data).forEach((item, index) => {
    if (
      item.firstName.toLowerCase().includes(value) ||
      item.lastName.toLowerCase().includes(value) ||
      `${item.lastName} ${item.firstName}`.toLowerCase().includes(value) ||
      `${item.firstName} ${item.lastName}`.toLowerCase().includes(value)
    ) {
      sortedData[index] = data[index];
    }
  });
  return sortedData;
};

class List extends React.Component {
  handleChange = debounce(value => {
    this.setState({ value });
  }, 300);

  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  deleteItem = id => {
    const { deleteUser, data } = this.props;
    deleteUser({ lastDeletedUser: data[id].username });
  };

  getRealIndex = id => {
    const { data } = this.props;
    const { value } = this.state;
    const sortedData = getData(sortData(value, data || []));
    return Object.values(data).findIndex(x => x.username === sortedData[id][0].username);
  };

  render() {
    const { data, history } = this.props;
    const { value } = this.state;
    const sortedData = getData(sortData(value, data || []));

    if (!data) return <h1 className="loading">Loading...</h1>;
    return (
      <div className="container">
        <h1 className="list-h1">List of users</h1>
        <input
          placeholder="Search"
          className={`search-items${value ? ' not-empty' : ''}`}
          onChange={e => this.handleChange(e.target.value)}
        />
        <Pagination todosPerPage={1} displayData={sortedData}>
          <Table
            titles={['name', 'company', 'contacts', 'last update']}
            editAllowed
            deleteAllowed
            isUserTable
            tableClass="list-of-users"
            onDelete={id => this.deleteItem(this.getRealIndex(id))}
            onEdit={id => history.push(`/list/profile/${this.getRealIndex(id) + 1}/edit`)}
            onView={id => history.push(`/list/profile/${this.getRealIndex(id) + 1}`)}
          />
        </Pagination>
        {Object.keys(data).length === 0 && (
          <div>
            <h1>No users here :(</h1>
            <Button onClick={() => history.push('/add')} className="btn add-user">
              Add user
            </Button>
          </div>
        )}
      </div>
    );
  }
}

List.propTypes = {
  data: PropTypes.shape({}),
  deleteUser: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

List.defaultProps = {
  data: undefined,
};

const mapStateToProps = ({ dataStored }) => ({
  data: dataStored.users,
});

const mapDispatchToProps = {
  deleteUser: deleteUserAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
