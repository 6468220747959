import { select, takeLatest, put, all, fork } from 'redux-saga/effects';

function* userNextStep() {
  const state = yield select();
  if (state.tabsVariables.activeTab === state.tabsVariables.tabsEnabled) {
    yield put({ type: 'UNLOCK_NEXT_TAB', payload: '' });
  }
  yield put({ type: 'SELECT_TAB', activeTab: state.tabsVariables.activeTab + 1 });
  yield put({ type: 'USER_SET_DATA', data: state.form.profile.values });
}

function* watchUserNextStep() {
  yield takeLatest(['USER_NEXT_STEP'], userNextStep);
}

function* userSetData() {
  const data = yield select(store => store.currentUser);
  localStorage.removeItem('profilePicture');
  localStorage.removeItem('user');
  if (data && Object.prototype.hasOwnProperty.call(data, 'profilePicture')) {
    const reader = new FileReader();
    reader.onload = item => {
      localStorage.setItem('profilePicture', item.target.result);
    };
    reader.readAsDataURL(data.profilePicture.file);
  }
  localStorage.setItem('user', JSON.stringify(data));
}

function* watchUserSetData() {
  yield takeLatest(['USER_SET_DATA'], userSetData);
}

export default function* userCurrentSagas() {
  yield all([fork(watchUserNextStep), fork(watchUserSetData)]);
}
