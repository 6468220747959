import React from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';

const Phone = ({ input, label, meta: { touched, error }, htmlFor, isRequired }) => (
  <div className={`item ${isRequired ? 'required' : ''}`}>
    <label className={htmlFor} htmlFor={htmlFor}>
      {label}
    </label>
    <div className="error">
      <InputMask mask="+7 (999) 999-99-99" {...input}>
        {inputProps => (
          <input {...inputProps} id={htmlFor} type="tel" className={`${touched && error ? ' input-error' : ''}`} />
        )}
      </InputMask>
      {touched && error && <span className="error-text">{error}</span>}
    </div>
  </div>
);

Phone.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  htmlFor: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
};

Phone.defaultProps = {
  isRequired: false,
};

export default Phone;
