import React, { memo } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// components
import { ReactComponent as Logo } from 'icons/Logo.svg';
import { ReactComponent as AddUser } from 'icons/add_users.svg';
import { ReactComponent as ListOfUsers } from 'icons/list_of_users.svg';
// styles
import './Header.scss';

const Header = () => (
  <header>
    <div className="container">
      <NavLink to="/list">
        <Logo alt="logo" className="logo-img" />
      </NavLink>
      <div className="nav">
        <NavLink to="/add" activeClassName="active">
          <AddUser alt="new user" />
          <p>Add new user</p>
        </NavLink>
        <NavLink to="/list" activeClassName="active">
          <ListOfUsers alt="user list" />
          <p>List of users</p>
        </NavLink>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default memo(withRouter(Header));
