import React from 'react';
import PropTypes from 'prop-types';
// styles
import './RadioButton.scss';

const RadioButton = ({ input, meta: { touched, error }, options }) => (
  <div className="error">
    {options.map(o => (
      <label key={o.value} className="error-label">
        <input type="radio" {...input} value={o.value} checked={o.value === input.value} />
        <span>{o.title}</span>
      </label>
    ))}
    {touched && error && <span className="error-text">{error}</span>}
  </div>
);

RadioButton.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
};

export default RadioButton;
