import React from 'react';
import PropTypes from 'prop-types';
// styles
import './RenderField.scss';

const RenderField = ({
  input,
  label,
  type,
  meta: { touched, error },
  htmlFor,
  isRequired,
  onChange,
  onBlur,
  value,
  idDisabled,
}) => (
  <div className={`item ${isRequired ? 'required' : ''} ${type === 'checkbox' ? 'checkbox' : ''}`}>
    <label className={htmlFor}>
      <p>{label}</p>
      <div className="error">
        <input
          {...input}
          onChange={onChange || input.onChange}
          onBlur={onBlur || input.onBlur}
          value={value || input.value}
          placeholder={label}
          type={type}
          disabled={idDisabled}
          className={`${touched && error ? ' input-error' : ''}`}
          id={htmlFor}
        />
        {touched && error && <span className="error-text">{error}</span>}
      </div>
    </label>
  </div>
);

RenderField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  htmlFor: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  idDisabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

RenderField.defaultProps = {
  isRequired: false,
  idDisabled: false,
  value: undefined,
  onBlur: undefined,
  onChange: undefined,
};

export default RenderField;
