import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
// icons
import { ReactComponent as User } from 'icons/list_of_users.svg';
// redux-actions
import { selectTabAction, enableTabsAction } from 'store/modules/tabsVariables';
import { userSetDataAction } from 'store/modules/currentUser';
// constants
import { hobbies } from 'helpers/variables';
// components
import NotFound from '../NotFound/NotFound';
import Section from './components/Section/Section';
// styles
import './profile.scss';

const formatSkills = hasSkills => hasSkills.map(item => item.label).join(', ');

const formatHobbies = data => hobbies.filter(item => data[item.value]).map(hobby => hobby.label);

const formatDate = initDate => {
  const date = typeof initDate !== 'object' ? new Date(initDate) : initDate;
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 101).toString().substring(1);
  const day = (date.getDate() + 100).toString().substring(1);
  return `${day}.${month}.${year}`;
};

const setData = user => {
  const result = {
    account: {},
    profile: {},
    contacts: {},
    capabilities: {},
  };
  const formattedSkills = formatSkills(user.skills);
  const formattedHobbies = formatHobbies(user);
  result.account.username = { label: 'Username', value: user.username };
  result.account.password = { label: 'Password', value: user.password.replace(/./gi, '*') };
  result.profile.firstName = { label: 'First Name', value: user.firstName };
  result.profile.lastName = { label: 'Last Name', value: user.lastName };
  if (user.birthDate) result.profile.birthDate = { label: 'Birth Date', value: formatDate(user.birthDate) };
  result.profile.email = { label: 'Email', value: user.email };
  if (user.address) result.profile.address = { label: 'Address', value: user.address };
  result.contacts.company = { label: 'Company', value: user.company };
  if (user.github) result.contacts.github = { label: 'Github', value: user.github };
  if (user.facebook) result.contacts.facebook = { label: 'Facebook', value: user.facebook };
  result.contacts.language = { label: 'Language', value: user.language.label };
  if (user.fax) result.contacts.fax = { label: 'Fax', value: user.fax };
  if (user.phone1) result.contacts.phone1 = { label: 'Phone#1', value: user.phone1 };
  if (user.phone2) result.contacts.phone2 = { label: 'Phone#2', value: user.phone2 };
  if (user.phone3) result.contacts.phone3 = { label: 'Phone#3', value: user.phone3 };
  result.capabilities.skills = { label: 'Skills', value: formattedSkills };
  if (formattedHobbies.length !== 0) result.capabilities.hobbies = { label: 'Hobbies', value: formattedHobbies };
  if (user.info) {
    const info = user.info.split('\n');
    result.capabilities.info = { label: 'Additional information', value: info };
  }
  return result;
};

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  dispatchAndPush = (tab, id) => {
    const {
      selectTab,
      history: { push },
    } = this.props;
    selectTab(tab);
    push(`/list/profile/${id}/edit`);
  };

  render() {
    const { match, data } = this.props;
    const id = parseInt(match.params.id, 10);
    if (!data) return <h1 className="loading">Loading...</h1>;
    const user = data[id - 1];
    if (user === undefined) return <NotFound />;
    const userData = setData(user);
    return (
      <div className="user-profile container">
        <Link className="navigation-link" to="/list">
          <h2>User List</h2>
        </Link>
        <h1 className="h1-profile">User {user.username}</h1>
        <div className="content">
          <div className="columns">
            {user.profilePicture && user.profilePicture.file ? (
              <img
                src={user.profilePicture && URL.createObjectURL(user.profilePicture.file)}
                alt="user"
                className="user-logo"
              />
            ) : (
              <div className="profilePic">
                <User alt="profile pic" className="emptyPic" />
              </div>
            )}
          </div>
          <div className="columns">
            <Section title="Account" onClick={() => this.dispatchAndPush(1, id)} data={userData.account} />
            <Section title="Personal" onClick={() => this.dispatchAndPush(2, id)} data={userData.profile} />
            <Section title="Contacts" onClick={() => this.dispatchAndPush(3, id)} data={userData.contacts} />
            <Section title="Capabilities" onClick={() => this.dispatchAndPush(4, id)} data={userData.capabilities} />
          </div>
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  data: PropTypes.shape({}),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  selectTab: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

Profile.defaultProps = {
  data: undefined,
};

const mapStateToProps = ({ dataStored }) => ({
  data: dataStored.users,
});

const mapDispatchToProps = {
  enableTabs: enableTabsAction,
  selectTab: selectTabAction,
  setUserData: userSetDataAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Profile));
