/* global google */
import React from 'react';
import PropTypes from 'prop-types';

class Autocomplete extends React.Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    label: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }).isRequired,
    htmlFor: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
  };

  static defaultProps = {
    isRequired: false,
  };

  constructor(props) {
    super(props);
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
  }

  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current, { types: ['geocode'] });
    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  }

  handlePlaceChanged() {
    const {
      input: { onChange },
    } = this.props;
    const place = this.autocomplete.getPlace();
    onChange(place.formatted_address);
  }

  render() {
    const {
      input,
      label,
      meta: { touched, error },
      htmlFor,
      isRequired,
    } = this.props;
    return (
      <div className={`item ${isRequired ? 'required' : ''}`}>
        <label htmlFor={htmlFor}>{label}</label>
        <div className="error">
          <input
            ref={this.autocompleteInput}
            id="address"
            type="text"
            onChange={input.onChange}
            value={input.value}
            className={`${touched && error ? ' input-error' : ''}`}
          />
          {touched && error && <span className="error-text">{error}</span>}
        </div>
      </div>
    );
  }
}

export default Autocomplete;
