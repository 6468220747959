import { action as actionDispatch } from 'helpers/helpers';

const SELECT_TAB = 'SELECT_TAB';
const UNLOCK_NEXT_TAB = 'UNLOCK_NEXT_TAB';
const DISABLE_TABS = 'DISABLE_TABS';
const ENABLE_TABS = 'ENABLE_TABS';

const initialState = {
  activeTab: 1,
  tabsEnabled: 1,
};

export default function tabsVariablesReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_TAB:
      return { ...state, activeTab: action.activeTab };
    case UNLOCK_NEXT_TAB:
      return { ...state, tabsEnabled: state.tabsEnabled + 1 };
    case DISABLE_TABS:
      return { ...state, tabsEnabled: 1 };
    case ENABLE_TABS:
      return { ...state, tabsEnabled: action.tabsEnabled };
    default:
      return state;
  }
}

export const selectTabAction = payload => actionDispatch(SELECT_TAB, { activeTab: payload });
export const unlockNextTabAction = payload => actionDispatch(UNLOCK_NEXT_TAB, { tabsEnabled: payload });
export const disableTabsAction = payload => actionDispatch(DISABLE_TABS, { tabsEnabled: payload });
export const enableTabsAction = payload => actionDispatch(ENABLE_TABS, { tabsEnabled: payload });
