import React from 'react';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// actions
import { loadDataAction } from 'store/modules/dataStored';
// helpers
import { history, seedUsers } from 'helpers/helpers'; // remove if user seed needed
// components
import Header from 'components/Header/Header';
import NotFound from './NotFound/NotFound';
import List from './List/List';
import EditProfile from './EditProfile/EditProfile';
import Profile from './Profile/Profile';
// styles
import 'styles/main.scss';

class App extends React.Component {
  static propTypes = {
    loadData: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // seedUsers(100);
    const { loadData } = this.props;
    loadData();
  }

  render() {
    return (
      <Router history={history}>
        <Header />
        <Switch>
          <Route path="/add" render={props => <EditProfile {...props} key="new-user" />} />
          <Route path="/list/profile/:id/edit" render={props => <EditProfile {...props} key="old-user" isEditing />} />
          <Route path="/list/profile/:id" component={Profile} />
          <Route path="/list" component={List} />
          <Route path="/" exact render={() => <Redirect to="/list" />} />
          <Route component={NotFound} />
        </Switch>
        <button onClick={() => seedUsers(50)}>seed 50 users</button>
      </Router>
    );
  }
}

const mapDispatchToProps = {
  loadData: loadDataAction,
};

export default connect(
  null,
  mapDispatchToProps
)(App);
