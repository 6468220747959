import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
// icons
import { ReactComponent as Calendar } from 'icons/calendar.svg';
// styles
import './datepicker.css';
import './MyDatePicker.scss';

class MyDatePicker extends React.Component {
  static propTypes = {
    startDate: PropTypes.instanceOf(Date),
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
      value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    }).isRequired,
    label: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }).isRequired,
    htmlFor: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
  };

  static defaultProps = {
    startDate: undefined,
    isRequired: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      startDate: props.startDate,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    const { input } = this.props;
    this.setState({
      startDate: date,
    });
    input.onChange(date);
  }

  render() {
    const { startDate } = this.state;
    const {
      input,
      input: { onBlur },
      label,
      meta: { touched, error },
      htmlFor,
      isRequired,
    } = this.props;
    return (
      <div className={`item birth-date ${isRequired ? 'required' : ''}`}>
        <label htmlFor={htmlFor}>{label}</label>
        <div className="error">
          <div className={`${touched && error ? 'input-error' : ''}`}>
            <DatePicker
              {...input}
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={this.handleChange}
              onBlur={() => onBlur(startDate)}
            />
          </div>
          {touched && error && <span className="error-text">{error}</span>}
          <Calendar alt="calendar icon" className="calendar-icon" />
        </div>
      </div>
    );
  }
}

export default MyDatePicker;
