import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormSyncErrors } from 'redux-form';
import PropTypes from 'prop-types';
// redux-actions
import { userNextStepAction } from 'store/modules/currentUser';
import { disableTabsAction, enableTabsAction } from 'store/modules/tabsVariables';
// validation funcs
import { required, minLength3, passwordsMustMatch, imgLessThanMb } from 'helpers/validate';
// components
import PasswordInput from 'components/Forms/PasswordInput/PasswordInput';
import DropZoneField from 'components/Forms/Image/Image';
import RenderField from 'components/Forms/RenderField/RenderField';
import Buttons from '../components/Buttons/Buttons';
// styles
import './Account.css';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileSet: false,
      imageFile: {
        preview:
          (props.initialValues &&
            props.initialValues.profilePicture &&
            props.initialValues.profilePicture.file &&
            URL.createObjectURL(props.initialValues.profilePicture.file)) ||
          undefined,
        size:
          (props.initialValues && props.initialValues.profilePicture && props.initialValues.profilePicture.size) || 0,
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.initialValues &&
      nextProps.initialValues.profilePicture &&
      nextProps.initialValues.profilePicture.file !== prevState.imageFile.file
    ) {
      if (!prevState.fileSet) {
        return {
          fileSet: true,
          imageFile: {
            ...nextProps.initialValues.profilePicture,
            preview: URL.createObjectURL(nextProps.initialValues.profilePicture.file),
          },
        };
      }
    }
    return null;
  }

  componentDidMount() {
    const { form } = this.props;
    if (
      Object.prototype.hasOwnProperty.call(form, 'values') &&
      Object.prototype.hasOwnProperty.call(form, 'profilePicture')
    ) {
      this.setState({ imageFile: form.values.profilePicture });
    }
  }

  handleOnDrop = (newImageFile, onChange) => {
    const imageFile = {
      file: newImageFile[0],
      name: newImageFile[0].name,
      preview: URL.createObjectURL(newImageFile[0]),
      size: newImageFile[0].size,
    };
    this.setState({ imageFile }, () => onChange(imageFile));
  };

  userExists = value => {
    const { data, isEditing } = this.props;
    return !Object.values(data).filter(dataItem => dataItem.username === value)[0] || isEditing
      ? undefined
      : 'User already exists!';
  };

  render() {
    const { isEditing, formErrors, valid, touch, nextStep, onSubmit } = this.props;
    const { imageFile } = this.state;
    return (
      <form className="accountForm" onSubmit={e => onSubmit(e, valid, nextStep, touch, formErrors)}>
        <div className="columns">
          <div className="error">
            <Field
              name="profilePicture"
              component={DropZoneField}
              type="file"
              imagefile={imageFile}
              handleOnDrop={this.handleOnDrop}
              validate={imgLessThanMb}
              allowCropping
            />
          </div>
        </div>
        <div className="columns">
          <Field
            label="User name"
            htmlFor="username"
            name="username"
            component={RenderField}
            type="text"
            validate={[required, minLength3, this.userExists]}
            id="username"
            isRequired
            idDisabled={isEditing}
          />
          <Field
            label="Password"
            htmlFor="password"
            name="password"
            component={PasswordInput}
            validate={[required, minLength3]}
            id="password"
            isRequired
          />
          <Field
            label="Repeat password"
            htmlFor="passwordConfirm"
            name="passwordConfirm"
            component={PasswordInput}
            validate={[required, minLength3, passwordsMustMatch]}
            id="passwordConfirm"
            isRequired
          />
          <Buttons isEditing={isEditing} />
        </div>
      </form>
    );
  }
}

Form.propTypes = {
  isEditing: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  form: PropTypes.shape({
    values: PropTypes.shape({
      profilePicture: PropTypes.shape({
        file: PropTypes.shape({}),
        size: PropTypes.number,
        preview: PropTypes.string,
      }),
    }),
  }),
  formErrors: PropTypes.shape({}).isRequired,
  valid: PropTypes.bool.isRequired,
  touch: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    profilePicture: PropTypes.shape({
      file: PropTypes.shape({}),
      size: PropTypes.number,
    }),
  }),
};

Form.defaultProps = {
  initialValues: {},
  isEditing: false,
  data: {},
  form: {},
};

const mapStateToProps = state => ({
  formErrors: getFormSyncErrors('profile')(state),
  form: state.form.profile,
  data: state.dataStored.users,
});

const mapDispatchToProps = {
  enableTabs: enableTabsAction,
  disableTabs: disableTabsAction,
  nextStep: userNextStepAction,
};

const AccountForm = reduxForm({
  form: 'profile',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form)
);

export default AccountForm;
