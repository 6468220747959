import { createBrowserHistory } from 'history';
import faker from 'faker';
import localforage from 'localforage';

export const history = createBrowserHistory();

export const timeAgo = date => {
  const delta = Math.round((+new Date() - date) / 1000);

  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;

  let someTimeAgo;

  if (delta < 30) {
    someTimeAgo = 'just now';
  } else if (delta < minute) {
    someTimeAgo = `${delta} seconds ago`;
  } else if (delta < 2 * minute) {
    someTimeAgo = 'a minute ago';
  } else if (delta < hour) {
    someTimeAgo = `${Math.floor(delta / minute)} minutes ago`;
  } else if (Math.floor(delta / hour) === 1) {
    someTimeAgo = '1 hour ago';
  } else if (delta < day) {
    someTimeAgo = `${Math.floor(delta / hour)} hours ago`;
  } else if (delta < day * 2) {
    someTimeAgo = 'yesterday';
  } else if (delta < week) {
    someTimeAgo = `${Math.floor(delta / day)} days ago`;
  } else if (Math.floor(delta / week) === 1) {
    someTimeAgo = '1 week ago';
  } else if (delta < month) {
    someTimeAgo = `${Math.floor(delta / week)} weeks ago`;
  } else if (Math.floor(delta / month) === 1) {
    someTimeAgo = '1 month ago';
  } else {
    someTimeAgo = `${Math.floor(delta / month)} months ago`;
  }
  return someTimeAgo;
};

export function action(type, payload = {}) {
  return { type, ...payload };
}

export const nextPage = (e, valid, nextStep, touch, formErrors, tab) => {
  if (e) e.preventDefault();
  if (valid) nextStep(tab || undefined);
  else touch(...Object.keys(formErrors));
};

export const getBase64 = file => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return reader.result;
};

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    n -= 1;
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const seedUsers = n => {
  [...Array(n)].forEach(() => {
    let result = {};
    const password = faker.internet.password();
    result = {
      ...result,
      username: faker.internet.userName(),
      password,
      passwordConfirm: password,
      firstName: faker.name.firstName(),
      lastName: faker.name.lastName(),
      birthDate: faker.date.between('1940-01-01', '2000-01-01'),
      email: faker.internet.email(),
      address: `${faker.address.streetAddress()}, ${faker.address.zipCode()}, ${faker.address.city()}, ${faker.address.country()}`,
      company: faker.company.companyName(),
      github: faker.internet.url(),
      facebook: faker.internet.url(),
      skills: [{ value: 'js', label: 'Javascript' }],
      info: faker.lorem.lines(),
      language: { value: 'en', label: 'English' },
    };
    fetch(faker.image.avatar())
      .then(res => res.blob())
      .then(blob => {
        result = { ...result, profilePicture: { file: blob, size: blob.size } };
        localforage.setItem(result.username, { ...result, lastUpdate: new Date() });
      });
  });
};
