import React, { Component } from 'react';
import PropTypes from 'prop-types';
// styles
import './Tab.scss';

class Tab extends Component {
  onClick = () => {
    const { tabNumber, onClick } = this.props;
    onClick(tabNumber);
  };

  render() {
    const {
      onClick,
      props: { activeTab, label, tabNumber, disabled },
    } = this;

    let className = 'tab-list-item';

    if (activeTab === tabNumber) {
      className += ' tab-list-active';
    }

    return (
      <button
        type="button"
        tabIndex={tabNumber}
        className={className}
        onClick={onClick}
        onKeyUp={() => {}}
        disabled={disabled}
      >
        <p>{`${tabNumber}`}</p>
        <p className="tab-label">{`. ${label}`}</p>
      </button>
    );
  }
}

Tab.propTypes = {
  tabNumber: PropTypes.number.isRequired,
  activeTab: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Tab.defaultProps = {
  disabled: true,
};

export default Tab;
