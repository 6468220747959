import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// actions
import { selectTabAction } from 'store/modules/tabsVariables';
// helpers
import { nextPage } from 'helpers/helpers';
// components
import Tab from './Tab/Tab';
// styles
import './Tabs.scss';

class Tabs extends Component {
  onClickTabItem = tab => {
    const { selectTab, valid, touch, formErrors, activeTab, isEditing } = this.props;
    if (isEditing || tab > activeTab) nextPage(null, valid, selectTab, touch, formErrors, tab);
    else selectTab(tab);
  };

  render() {
    const {
      onClickTabItem,
      props: { children, activeTab, tabsEnabled, isEditing },
    } = this;

    return (
      <div className="tabs">
        <ol className="tab-list">
          {children.map(child => {
            const { label, tabNumber } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                tabNumber={tabNumber}
                onClick={onClickTabItem}
                disabled={!isEditing ? !(tabNumber <= tabsEnabled) : false}
              />
            );
          })}
        </ol>
        <div className="tab-content">
          {children.map(child => {
            if (child.props.tabNumber !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

Tabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
  activeTab: PropTypes.number.isRequired,
  tabsEnabled: PropTypes.number.isRequired,
  selectTab: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  touch: PropTypes.func.isRequired,
  formErrors: PropTypes.shape({}),
  isEditing: PropTypes.bool,
};

Tabs.defaultProps = {
  formErrors: {},
  isEditing: false,
};

const mapDispatchToProps = {
  selectTab: selectTabAction,
};

const mapStateToProps = ({ tabsVariables: { activeTab, tabsEnabled } }) => ({
  activeTab,
  tabsEnabled,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tabs);
