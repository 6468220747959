import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
// styles
import './Pagination.scss';

class Pagination extends React.Component {
  static propTypes = {
    displayData: PropTypes.instanceOf(Array),
    children: PropTypes.node,
  };

  static defaultProps = {
    children: <React.Fragment />,
    displayData: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      items: props.displayData,
      currentPage: 1,
      itemsPerPage: 5,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const nextPageId =
      state.itemsPerPage * state.currentPage - props.displayData.length >= state.itemsPerPage ? 1 : state.currentPage;
    if (props.displayData !== state.items) return { items: props.displayData, currentPage: nextPageId };
    return null;
  }

  handleChange = event => {
    this.setState({ itemsPerPage: parseInt(event.target.value, 10), currentPage: 1 });
  };

  handleClick(id) {
    this.setState({
      currentPage: id + 1,
    });
  }

  render() {
    const { items, currentPage, itemsPerPage } = this.state;
    const { children } = this.props;
    const indexOfLastTodo = currentPage * itemsPerPage;
    const indexOfFirstTodo = indexOfLastTodo - itemsPerPage;
    const currentItems = items.slice(indexOfFirstTodo, indexOfLastTodo);
    const pageNumbers = [];
    const numberOfPages = Math.ceil(items.length / itemsPerPage);
    [...Array(numberOfPages)].forEach((_, i) => pageNumbers.push(i + 1));
    const renderPageNumbers = () => {
      const pages = pageNumbers.map((number, id) => (
        <Button
          key={`pagination-page#-${number}`}
          className={`btn-small page-${number}${id + 1 === currentPage ? ' active' : ''}`}
          onClick={() => this.handleClick(id)}
          onKeyDown={() => {}}
        >
          {number}
        </Button>
      ));
      let newPages = [];
      if (numberOfPages > 4) {
        if (currentPage < 5) {
          newPages = pages.slice(0, currentPage + 2);
          if (pages.length - newPages.length > 0) {
            if (pages.length - newPages.length > 1) {
              newPages.push(<p key="first-ellipsis">...</p>);
            }
            newPages.push(pages[pages.length - 1]);
          }
        } else if (numberOfPages - currentPage < 4) {
          newPages = pages.slice(currentPage - 3, pages.length);
          if (pages.length - newPages.length > 1) {
            newPages.unshift(<p key="last-ellipsis">...</p>);
            newPages.unshift(pages[0]);
          }
        } else {
          newPages.push(pages[0]);
          newPages.push(<p key="first-ellipsis">...</p>);
          [...Array(5)].forEach((_, index) => {
            newPages.push(pages[index - 3 + currentPage]);
          });
          newPages.push(<p key="last-ellipsis">...</p>);
          newPages.push(pages[pages.length - 1]);
        }
      }

      return newPages && newPages.length !== 0 ? newPages : pages;
    };
    return (
      <div>
        <div className="pagination-content">
          {React.cloneElement(children, { displayData: currentItems, currentPage, itemsPerPage })}
        </div>
        {items.length !== 0 && (
          <React.Fragment>
            <ul className="page-numbers">{renderPageNumbers()}</ul>
            <div className="items-per-page">
              <p>Select items per page: </p>
              <select value={itemsPerPage} onChange={this.handleChange}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default Pagination;
