export const languages = [
  'English, USA',
  'English, UK',
  'English, UK1',
  'English, UK2',
  'English, UK3',
  'English, UK4',
  'English, UK5',
];

export const skills = [
  { value: 'html', label: 'HTML' },
  { value: 'css', label: 'CSS' },
  { value: 'js', label: 'Javascript' },
  { value: 'react', label: 'React' },
  { value: 'angular', label: 'Angular' },
  { value: 'nodejs', label: 'NodeJS' },
  { value: 'python', label: 'Python' },
  { value: 'php', label: 'PHP' },
  { value: 'ruby', label: 'Ruby On Rails' },
  { value: 'sql', label: 'SQL' },
  { value: 'backbonejs', label: 'BackboneJS' },
  { value: 'web', label: 'Web Design' },
  { value: 'pm', label: 'Project management' },
  { value: 'git', label: 'Git' },
  { value: 'aws', label: 'AWS Lambda' },
  { value: 'firbase', label: 'Firebase' },
];

export const languagesList = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'French' },
  { value: 'es', label: 'Spanish' },
  { value: 'ar', label: 'Arabic' },
  { value: 'cmn', label: 'Mandarin' },
  { value: 'ru', label: 'Russian' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'de', label: 'German' },
  { value: 'ja', label: 'Japanese' },
  { value: 'hi', label: 'Hindi' },
  { value: 'ms', label: 'Malay' },
  { value: 'fa', label: 'Persian' },
  { value: 'sw', label: 'Swahili' },
  { value: 'ta', label: 'Tamil' },
  { value: 'it', label: 'Italian' },
  { value: 'nl', label: 'Dutch' },
  { value: 'bn', label: 'Bengali' },
  { value: 'tr', label: 'Turkish' },
  { value: 'vi', label: 'Vietnamese' },
  { value: 'pl', label: 'Polish' },
  { value: 'jv', label: 'Javanese' },
  { value: 'pa', label: 'Punjabi' },
  { value: 'th', label: 'Thai' },
  { value: 'ko', label: 'Korean' },
];

export const hobbies = [
  {
    value: 'art',
    label: 'Art',
  },
  {
    value: 'sport',
    label: 'Sport, fitness, aerobica and staff like that',
  },
  {
    value: 'games',
    label: 'I just want to play games, I’m not living in this life',
  },
  {
    value: 'nothing',
    label: 'I’m a female... I’m doing nothing. Every day',
  },
  {
    value: 'music',
    label: 'Guitar, guitar and guitar again. I’m fall in love with it.',
  },
  {
    value: 'wtf',
    label: 'WTF is “hobbies”???',
  },
];
