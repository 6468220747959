import { action as actionDispatch } from 'helpers/helpers';

const ADD_USER = 'ADD_USER';
const LOAD_DATA = 'LOAD_DATA';
const SET_DATA = 'SET_DATA';
const LOAD_FROM_STORAGE = 'LOAD_FROM_STORAGE';
const DELETE_USER = 'DELETE_USER';
const CLEAR_STORAGE = 'CLEAR_STORAGE';

const initialState = {
  localStorage: {},
  tabsEnabled: 1,
};

export default function dataStoredReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_USER:
    case LOAD_DATA:
      return state;
    case SET_DATA:
      return { ...state, users: { ...action.data } };
    case LOAD_FROM_STORAGE:
      return {
        ...state,
        localStorage: { ...state.localStorage, ...action.localStorage },
        tabsEnabled: action.tabsEnabled || state.tabsEnabled,
      };
    case CLEAR_STORAGE:
      return { ...state, localStorage: {}, tabsEnabled: 1 };
    case DELETE_USER:
      return { ...state, lastDeletedUser: action.lastDeletedUser };
    default:
      return state;
  }
}

export const loadDataAction = () => actionDispatch(LOAD_DATA);
export const addUserAction = () => actionDispatch(ADD_USER);
export const clearStorageAction = () => actionDispatch(CLEAR_STORAGE);
export const setDataAction = payload => actionDispatch(SET_DATA, payload);
export const loadFromStorageAction = payload => actionDispatch(LOAD_FROM_STORAGE, payload);
export const deleteUserAction = payload => actionDispatch(DELETE_USER, payload);
