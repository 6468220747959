import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// redux-actions
import { selectTabAction } from 'store/modules/tabsVariables';
// components
import Button from 'components/Button/Button';
// styles
import './Buttons.scss';

const value = (isEditing, activeTab, one, two, three) => {
  if (isEditing) {
    return one;
  }
  if (activeTab < 4) {
    return two;
  }
  return three;
};

const Buttons = ({ isEditing, activeTab, onClick }) => (
  <div className="item item-buttons">
    {!isEditing && activeTab !== 1 && (
      <Button className="back-btn" onClick={() => onClick(activeTab - 1)}>
        Back
      </Button>
    )}
    <Button type="submit" className={`btn${value(isEditing, activeTab, '', '', ' btn-finish')}`}>
      {`${value(isEditing, activeTab, 'Save', 'Forward', ' Finish')}`}
    </Button>
  </div>
);

Buttons.propTypes = {
  isEditing: PropTypes.bool,
  activeTab: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

Buttons.defaultProps = {
  isEditing: false,
};

const mapStateToProps = ({ tabsVariables: { activeTab } }) => ({
  activeTab,
});

const mapDispatchToProps = {
  onClick: selectTabAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Buttons);
