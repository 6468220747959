import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// styles
import './MySelect.css';
import './MySelect.scss';

class MySelect extends React.Component {
  constructor(props) {
    super(props);
    const { values } = props;
    this.state = {
      selectedOption: values || null,
    };
  }

  handleChange = selectedOption => {
    const { input } = this.props;
    this.setState({ selectedOption });
    input.onChange(selectedOption);
  };

  render() {
    const { selectedOption } = this.state;
    const {
      isMulti,
      options,
      input,
      label,
      meta: { touched, error, visited },
      htmlFor,
      isRequired,
    } = this.props;
    return (
      <div className={`item ${isRequired ? ' required' : ''}`}>
        <label htmlFor={htmlFor} className="error-label">
          {label}
          <div className="error" id={htmlFor}>
            <Select
              className={`my-select${touched && error ? ' input-error' : ''}${
                isMulti ? ' my-select-multi' : ' my-select-single'
              }${!visited ? ' my-select-not-visited' : ''}`}
              classNamePrefix="my-select"
              {...input}
              value={selectedOption}
              onChange={this.handleChange}
              onBlur={() => input.onBlur(input.value)}
              options={options}
              isMulti={isMulti}
            />
            {touched && error && <span className="error-text">{error}</span>}
          </div>
        </label>
      </div>
    );
  }
}

MySelect.propTypes = {
  input: PropTypes.shape({
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
      PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        })
      ),
    ]),
  }).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    visited: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  htmlFor: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  values: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
  ]),
  isMulti: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

MySelect.defaultProps = {
  isRequired: false,
  values: undefined,
  isMulti: false,
  options: undefined,
};

const mapStateToProps = state => ({ form: state.form.profile });

export default connect(mapStateToProps)(MySelect);
