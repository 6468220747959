import React, { Component } from 'react';
import PropTypes from 'prop-types';
// icons
import { ReactComponent as Edit } from 'icons/Edit.svg';
import { ReactComponent as Del } from 'icons/Close.svg';
// styles
import './Table.scss';

class Table extends Component {
  static propTypes = {
    tableClass: PropTypes.string,
    titles: PropTypes.arrayOf(PropTypes.string).isRequired,
    editAllowed: PropTypes.bool,
    deleteAllowed: PropTypes.bool,
    displayData: PropTypes.instanceOf(Array),
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onView: PropTypes.func,
    isUserTable: PropTypes.bool,
    currentPage: PropTypes.number,
    todosPerPage: PropTypes.number,
  };

  static defaultProps = {
    tableClass: '',
    editAllowed: false,
    deleteAllowed: false,
    displayData: [],
    onDelete: () => {},
    onEdit: () => {},
    onView: () => {},
    isUserTable: false,
    currentPage: 1,
    todosPerPage: 5,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentPage: props.currentPage,
      todosPerPage: props.todosPerPage,
      itemToDelete: -1,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.currentPage !== state.currentPage || props.todosPerPage !== state.todosPerPage)
      return { itemToDelete: -1, currentPage: props.currentPage, todosPerPage: props.todosPerPage };
    return null;
  }

  confirmDelete = id => {
    const { itemToDelete } = this.state;
    if (itemToDelete !== -1 && itemToDelete === id) this.setState({ itemToDelete: -1 });
    else this.setState({ itemToDelete: id });
  };

  deleteItem = id => {
    const { onDelete } = this.props;
    onDelete(id);
    this.setState({ itemToDelete: -1 });
  };

  render() {
    const {
      tableClass,
      titles,
      editAllowed,
      deleteAllowed,
      displayData,
      onEdit,
      onView,
      isUserTable,
      currentPage,
      todosPerPage,
    } = this.props;
    const { itemToDelete } = this.state;
    return (
      <div className="table-wrapper">
        <table className={tableClass}>
          <thead>
            <tr>
              {titles.map(item => (
                <td key={`title-${item}`}>{item}</td>
              ))}
              {editAllowed && <td className="tool-td" />}
              {deleteAllowed && <td className="tool-td" />}
            </tr>
          </thead>
          <tbody>
            {displayData ? (
              Object.values(displayData).map((arrayOfItems, index) => (
                <tr
                  className={`${index === itemToDelete ? 'item-to-delete' : ''}`}
                  key={`${tableClass || 'user-table'}-row-${index + 1}`}
                >
                  {arrayOfItems.map((item, i) => (
                    <React.Fragment key={`${tableClass || 'user-table'}-row-${index + 1}-cell-${i + 1}`}>
                      {i === 0 && isUserTable && (
                        <td className="user">
                          <button
                            type="button"
                            onClick={() => onView((currentPage - 1) * todosPerPage + index)}
                            className=" btn-small table-row"
                          >
                            <figure>
                              <img src={item.picture} alt="user" className="user-logo" />
                              <figcaption>
                                <div className="info">
                                  <div>
                                    <span>{item.firstName}</span>
                                    <span>{` ${item.lastName}`}</span>
                                  </div>
                                  <div>
                                    <span className="username">{item.username}</span>
                                  </div>
                                </div>
                              </figcaption>
                            </figure>
                          </button>
                        </td>
                      )}
                      {(!isUserTable || i !== 0) && <td>{item}</td>}
                    </React.Fragment>
                  ))}
                  {editAllowed && (
                    <td className="tool-td">
                      <button
                        type="button"
                        className="btn-small"
                        onClick={() => onEdit((currentPage - 1) * todosPerPage + index)}
                      >
                        <Edit alt="edit profile" />
                      </button>
                    </td>
                  )}
                  {deleteAllowed && (
                    <td className="tool-td">
                      <div className="delete-container">
                        <button type="button" className="btn-small" onClick={() => this.confirmDelete(index)}>
                          <Del alt="delete profile" />
                        </button>
                        {index === itemToDelete && (
                          <button
                            type="button"
                            className="btn-small delete-confirm"
                            onClick={() => this.deleteItem((currentPage - 1) * todosPerPage + index)}
                          >
                            x delete
                          </button>
                        )}
                      </div>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <h1 className="loading">Loading...</h1>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
export default Table;
