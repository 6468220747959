import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormSyncErrors } from 'redux-form';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// redux-actions
import { userNextStepAction, userClearDataAction } from 'store/modules/currentUser';
import { disableTabsAction } from 'store/modules/tabsVariables';
// validation funcs
import { required } from 'helpers/validate';
// constants
import { skills as options } from 'helpers/variables';
// components
import MySelect from 'components/Forms/MySelect/MySelect';
import RenderField from 'components/Forms/RenderField/RenderField';
import Buttons from '../components/Buttons/Buttons';
// styles
import './Capabilities.scss';

const Form = ({ form, formErrors, valid, touch, initialValues, isEditing, onSubmit, nextStep }) => (
  <form className="capabilitiesForm" onSubmit={e => onSubmit(e, valid, nextStep, touch, formErrors)}>
    <div className="columns">
      <Field
        label="Skills"
        htmlFor="skills"
        name="skills"
        component={MySelect}
        id="skills"
        options={options}
        validate={required}
        isMulti
        values={initialValues && initialValues.skills ? form.values.skills : null}
        isRequired
      />
      <div className="item info-textarea">
        <label htmlFor="info">Additional information</label>
        <Field name="info" component="textarea" id="info" maxLength={300} placeholder="Additional information" />
      </div>
    </div>
    <div className="columns">
      <Field
        label="Sport, fitness, aerobica and stuff like that"
        htmlFor="sport"
        name="sport"
        component={RenderField}
        type="checkbox"
        id="sport"
      />
      <Field
        label="I just want to play games. I'm not living in this life"
        htmlFor="games"
        name="games"
        component={RenderField}
        type="checkbox"
        id="games"
      />
      <Field
        label="I'm female... I'm doing nothing. EVery day."
        htmlFor="nothing"
        name="nothing"
        component={RenderField}
        type="checkbox"
        id="nothing"
      />
      <Field
        label="Guitar, guitar and guitar again. I'm fall in love with it."
        htmlFor="music"
        name="music"
        component={RenderField}
        type="checkbox"
        id="music"
      />
      <Field label='WTF is "hobbies"???' htmlFor="wtf" name="wtf" component={RenderField} type="checkbox" id="wtf" />
      <Buttons isEditing={isEditing} />
    </div>
  </form>
);

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  form: PropTypes.shape({
    values: PropTypes.shape({
      skills: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
  formErrors: PropTypes.shape({}),
  valid: PropTypes.bool.isRequired,
  touch: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  isEditing: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

Form.defaultProps = {
  formErrors: {},
  initialValues: {},
};

const mapStateToProps = state => ({
  formErrors: getFormSyncErrors('profile')(state),
  form: state.form.profile,
});

const mapDispatchToProps = {
  clearUserData: userClearDataAction,
  nextStep: userNextStepAction,
  disableTabs: disableTabsAction,
};

const CapabilitiesForm = reduxForm({
  form: 'profile',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(Form))
);

export default CapabilitiesForm;
