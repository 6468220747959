import React from 'react';
import PropTypes from 'prop-types';
import DropZone from 'react-dropzone';
import Cropper from 'react-cropper';
// helpers
import { dataURLtoFile } from 'helpers/helpers';
// components
import Button from 'components/Button/Button';
import Placeholder from './Placeholder/Placeholder';
// styles
import './Сropper.scss';

class DropZoneField extends React.Component {
  state = {
    isCropped: true,
  };

  onDrop = file => {
    const {
      handleOnDrop,
      input: { onChange },
    } = this.props;
    handleOnDrop(file, onChange);
    this.setState({ isCropped: false });
  };

  cropImage = () => {
    const {
      handleOnDrop,
      input: { onChange },
    } = this.props;
    const image = dataURLtoFile(this.cropper.getCroppedCanvas().toDataURL());
    handleOnDrop([image], onChange);
    this.setState({ isCropped: true });
  };

  closeCropper = () => this.setState({ isCropped: true });

  render() {
    const {
      imagefile,
      meta: { error, touched },
      allowCropping,
    } = this.props;
    const { isCropped } = this.state;
    return (
      <div className="preview-container">
        <DropZone
          accept="image/jpeg, image/png, image/gif, image/bmp"
          className="upload-container"
          onDrop={this.onDrop}
          multiple={false}
        >
          {props => <Placeholder {...props} error={error} touched={touched} imagefile={imagefile} />}
        </DropZone>
        {allowCropping && (
          <div className={`${isCropped ? 'crop-container-disabled' : 'crop-container-active'}`}>
            <Button className="btn-small" onClick={this.closeCropper}>
              x
            </Button>
            <Cropper
              style={{ height: 400, width: 400 }}
              className="crop-content"
              aspectRatio={1 / 1}
              ref={cropper => {
                this.cropper = cropper;
              }}
              src={imagefile.preview}
            />
            <Button onClick={this.cropImage} className="btn">
              Crop image
            </Button>
          </div>
        )}
      </div>
    );
  }
}

DropZoneField.propTypes = {
  allowCropping: PropTypes.bool,
  handleOnDrop: PropTypes.func.isRequired,
  imagefile: PropTypes.shape({
    file: PropTypes.shape({}),
    size: PropTypes.number,
    preview: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

DropZoneField.defaultProps = {
  allowCropping: false,
};

export default DropZoneField;
