import React from 'react';
import { Field, reduxForm, getFormSyncErrors } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// redux-actions
import { userNextStepAction } from 'store/modules/currentUser';
// validation funcs
import { required, minLength3, email, over18, radioRequired } from 'helpers/validate';
// components
import MyDatePicker from 'components/Forms/MyDatePicker/MyDatePicker';
import Autocomplete from 'components/Forms/Autocomplete/Autocomplete';
import RadioButton from 'components/Forms/RadioButton/RadioButton';
import RenderField from 'components/Forms/RenderField/RenderField';
import Buttons from '../components/Buttons/Buttons';

const emailExistsValidation = (value, { data, initialValues, isEditing }) => {
  return Object.values(data)
    .filter(dataItem => dataItem.email === value)
    .filter(mail => (isEditing ? mail.email !== initialValues.email : true)).length === 0
    ? undefined
    : 'User already exists!';
};

const Form = props => {
  const { isEditing, formErrors, valid, touch, nextStep, onSubmit, initialValues } = props;
  const emailExists = value => emailExistsValidation(value, props);
  return (
    <form className="profileForm" onSubmit={e => onSubmit(e, valid, nextStep, touch, formErrors)}>
      <div className="columns">
        <Field
          label="First name"
          htmlFor="firstName"
          name="firstName"
          component={RenderField}
          type="text"
          validate={[required, minLength3]}
          id="firstName"
          isRequired
        />
        <Field
          label="Last name"
          htmlFor="lastName"
          name="lastName"
          component={RenderField}
          type="text"
          validate={[required, minLength3]}
          id="lastName"
          isRequired
        />
        <Field
          label="Birth date"
          htmlFor="birthDate"
          name="birthDate"
          component={MyDatePicker}
          startDate={initialValues && initialValues.birthDate ? new Date(initialValues.birthDate) : undefined}
          type="date"
          validate={over18}
          id="birthDate"
        />
      </div>
      <div className="columns">
        <Field
          label="Email"
          htmlFor="email"
          name="email"
          component={RenderField}
          type="email"
          validate={[required, email, emailExists]}
          id="email"
          isRequired
        />
        <Field label="Address" htmlFor="address" name="address" component={Autocomplete} type="address" id="address" />
        <div className="item">
          <span>Gender</span>
          <div className="radio">
            <Field
              component={RadioButton}
              name="gender"
              options={[{ title: 'Male', value: 'male' }, { title: 'Female', value: 'female' }]}
              validate={radioRequired}
            />
          </div>
        </div>
        <Buttons isEditing={isEditing} />
      </div>
    </form>
  );
};

Form.propTypes = {
  isEditing: PropTypes.bool,
  formErrors: PropTypes.shape({}).isRequired,
  valid: PropTypes.bool.isRequired,
  touch: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    email: PropTypes.string,
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    birthDate: PropTypes.instanceOf(Date),
  }),
};

Form.defaultProps = {
  isEditing: false,
  initialValues: {},
};

const mapStateToProps = state => ({
  formErrors: getFormSyncErrors('profile')(state),
  data: state.dataStored.users,
});

const mapDispatchToProps = {
  nextStep: userNextStepAction,
};

const ProfileForm = reduxForm({
  form: 'profile',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form)
);

export default ProfileForm;
