import { takeLatest, all, fork, select } from 'redux-saga/effects';

function* changeTabsLocked() {
  const tabsEnabled = yield select(store => store.tabsVariables.tabsEnabled);
  localStorage.setItem('tabsEnabled', tabsEnabled);
}

function* watchChangeTabsLocked() {
  yield takeLatest(['UNLOCK_NEXT_TAB', 'DISABLE_TABS', 'ENABLE_TABS'], changeTabsLocked);
}

export default function* tabsVariablesSagas() {
  yield all([fork(watchChangeTabsLocked)]);
}
