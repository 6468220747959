import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormSyncErrors } from 'redux-form';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// redux-actions
import { selectTabAction, enableTabsAction, disableTabsAction } from 'store/modules/tabsVariables';
import { userSetDataAction, userClearDataAction } from 'store/modules/currentUser';
import { addUserAction, loadFromStorageAction, clearStorageAction } from 'store/modules/dataStored';
// helpers
import { nextPage, dataURLtoFile } from 'helpers/helpers';
// components
import Tabs from 'components/Tabs/Tabs';
import Button from 'components/Button/Button';
import NotFound from 'pages/NotFound/NotFound';
import Account from './Account/Account';
import Profile from './Profile/Profile';
import Contacts from './Contacts/Contacts';
import Capabilities from './Capabilities/Capabilities';
// styles
import './EditProfile.scss';

class EditProfile extends React.Component {
  state = {
    isLoadedFromStorage: false,
  };

  componentDidMount() {
    const { loadFromStorage, isEditing } = this.props;
    let localStorageData = {};
    if (!isEditing) {
      const userItem = localStorage.getItem('user');
      const user = userItem !== 'undefined' ? JSON.parse(userItem) : undefined;
      const tabsEnabled = parseInt(localStorage.getItem('tabsEnabled'), 10) || 1;
      const profilePicture = localStorage.getItem('profilePicture');
      let file;

      if (profilePicture) {
        file = dataURLtoFile(profilePicture, 'img.jpeg');
        localStorageData = {
          ...localStorageData,
          profilePicture: {
            preview: URL.createObjectURL(file),
            size: file.size,
          },
        };
      }

      if (user && user.birthDate) {
        user.birthDate = new Date(user.birthDate);
      }
      if (user) localStorageData = { ...localStorageData, ...user };
      if (file) localStorageData = { ...localStorageData, profilePicture: { file } };

      loadFromStorage({ localStorage: localStorageData, tabsEnabled });
    }
  }

  componentWillUnmount() {
    const { selectTab, clearUserData, isEditing, disableTabs } = this.props;
    selectTab(1);
    disableTabs();
    if (!isEditing) clearUserData();
  }

  onSubmit = (e, valid, nextStep, touch, formErrors) => {
    const { addUser, isEditing, history, tabsVariables, clearUserData, clearStorage } = this.props;
    e.preventDefault();
    if (valid) {
      if (isEditing) {
        addUser();
        history.push(`/list/profile/1`);
      } else if (tabsVariables.activeTab === 4) {
        addUser();
        clearUserData();
        clearStorage();
        localStorage.clear();
        history.push(`/list`);
      } else {
        nextPage(e, valid, nextStep, touch, formErrors);
      }
    } else touch(...Object.keys(formErrors));
  };

  completeData = () => {
    const { setUserData, data, selectTab, enableTabs } = this.props;
    setUserData({ data: data.localStorage });
    this.setState({ isLoadedFromStorage: true });
    enableTabs(data.tabsEnabled);
    selectTab(data.tabsEnabled);
  };

  closeLoadFromStorage = () => {
    this.setState({ isLoadedFromStorage: true });
  };

  render() {
    const {
      isEditing,
      user,
      data: { users, localStorage },
      match,
    } = this.props;
    const { isLoadedFromStorage } = this.state;
    let initValues = {};
    let id;
    if (isEditing) {
      if (!users) return <h1 className="loading">Loading...</h1>;
      id = parseInt(match.params.id, 10);
      if (users && users[id - 1]) initValues = users[id - 1];
      if (!users[id - 1]) return <NotFound />;
    } else initValues = user;
    return (
      <div className="container">
        {isEditing && (
          <Link className="navigation-link" to={`/list/profile/${id}`}>
            <h2>User Profile</h2>
          </Link>
        )}
        {!isEditing && <h1 className="h1-add-user">Adding new user</h1>}
        {isEditing && <h1 className="h1-edit-user">Editing</h1>}
        <Tabs {...this.props}>
          <div label="Account" tabNumber={1}>
            <Account isEditing={isEditing} initialValues={initValues} onSubmit={this.onSubmit} />
          </div>
          <div label="Profile" tabNumber={2}>
            <Profile isEditing={isEditing} initialValues={initValues} onSubmit={this.onSubmit} />
          </div>
          <div label="Contacts" tabNumber={3}>
            <Contacts isEditing={isEditing} initialValues={initValues} onSubmit={this.onSubmit} />
          </div>
          <div label="Capabilities" tabNumber={4}>
            <Capabilities isEditing={isEditing} initialValues={initValues} id={id} onSubmit={this.onSubmit} />
          </div>
        </Tabs>
        {!isLoadedFromStorage && Object.keys(localStorage).length !== 0 && !isEditing && (
          <div className="load-from-storage">
            <span>You have an unsaved user data. Do you want to complete it?</span>
            <Button onClick={this.completeData} className="btn-small">
              Continue
            </Button>
            <Button onClick={this.closeLoadFromStorage} className="btn-small close">
              x
            </Button>
          </div>
        )}
      </div>
    );
  }
}

EditProfile.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  clearUserData: PropTypes.func.isRequired,
  disableTabs: PropTypes.func.isRequired,
  addUser: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  user: PropTypes.shape({}),
  initialize: PropTypes.func.isRequired,
  selectTab: PropTypes.func.isRequired,
  userSelectedTab: PropTypes.number,
  enableTabs: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  loadFromStorage: PropTypes.func.isRequired,
  clearStorage: PropTypes.func.isRequired,
  data: PropTypes.shape({
    users: PropTypes.shape({}),
    localStorage: PropTypes.shape({}).isRequired,
    tabsEnabled: PropTypes.number.isRequired,
  }),
  tabsVariables: PropTypes.shape({
    activeTab: PropTypes.number.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    values: PropTypes.shape({}),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

EditProfile.defaultProps = {
  form: {},
  isEditing: false,
  user: {},
  data: undefined,
  userSelectedTab: 1,
  match: {
    params: {
      id: undefined,
    },
  },
};

const mapStateToProps = state => ({
  tabsVariables: state.tabsVariables,
  data: state.dataStored,
  user: state.currentUser,
  form: state.form.profile,
  formErrors: getFormSyncErrors('profile')(state),
});

const mapDispatchToProps = {
  enableTabs: enableTabsAction,
  selectTab: selectTabAction,
  addUser: addUserAction,
  setUserData: userSetDataAction,
  clearUserData: userClearDataAction,
  disableTabs: disableTabsAction,
  loadFromStorage: loadFromStorageAction,
  clearStorage: clearStorageAction,
};

export default reduxForm({
  form: 'profile',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditProfile)
);
