import React from 'react';
import { Link } from 'react-router-dom';
// components
import Button from '../../components/Button/Button';
// styles
import './NotFound.css';

const NotFound = () => {
  return (
    <div className="container">
      <h1>ERROR 404. Page not found</h1>
      <Link to="/list">
        <Button className="btn not-found">Home page</Button>
      </Link>
    </div>
  );
};

export default NotFound;
