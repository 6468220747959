import React, { useState } from 'react';
// icons
import { ReactComponent as PasswordIcon } from 'icons/icon_visibility.svg';
import { ReactComponent as PasswordIconHidden } from 'icons/icon_visibility_off.svg';
// components
import RenderField from '../RenderField/RenderField';
import Button from '../../Button/Button';
// styles
import './PasswordInput.css';

const PasswordInput = props => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  return (
    <div className="passwordField">
      <RenderField {...props} type={`${isVisible ? 'text' : 'password'}`} />
      <Button onClick={toggleVisibility} className="btn-small" tabIndex={-1}>
        {isVisible ? <PasswordIcon alt="password icon" /> : <PasswordIconHidden alt="password icon" />}
      </Button>
    </div>
  );
};

export default PasswordInput;
