import { action as actionDispatch } from 'helpers/helpers';

const USER_SET_DATA = 'USER_SET_DATA';
const USER_NEXT_STEP = 'USER_NEXT_STEP';
const USER_CLEAR_DATA = 'USER_CLEAR_DATA';

export default function userReducer(state = {}, action) {
  switch (action.type) {
    case USER_SET_DATA:
      return { ...state, ...action.data };
    case USER_NEXT_STEP:
      return { ...state, ...action.data };
    case USER_CLEAR_DATA:
      return {};
    default:
      return state;
  }
}

export const userSetDataAction = payload => actionDispatch(USER_SET_DATA, payload);
export const userNextStepAction = payload => actionDispatch(USER_NEXT_STEP, payload);
export const userClearDataAction = () => actionDispatch(USER_CLEAR_DATA);
