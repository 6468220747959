import { takeLatest, put, all, fork, call, select } from 'redux-saga/effects';
import retrieveData from 'helpers/api';
import localforage from 'localforage';

function* loadData() {
  const data = yield call(retrieveData);
  data.sort((a, b) => b.lastUpdate - a.lastUpdate);
  yield put({ type: 'SET_DATA', data });
}

function* addUser() {
  const submit = yield select(store => store.form.profile.values);
  const lastUpdate = new Date();
  yield localforage.setItem(submit.username, { ...submit, lastUpdate });
  yield put({ type: 'LOAD_DATA' });
}

function* deleteUser() {
  const userName = yield select(store => store.dataStored.lastDeletedUser);
  yield localforage.removeItem(userName);
  yield put({ type: 'LOAD_DATA' });
}

function* watchDeleteUser() {
  yield takeLatest(['DELETE_USER'], deleteUser);
}

function* watchAddUser() {
  yield takeLatest(['ADD_USER'], addUser);
}

function* watchLoadData() {
  yield takeLatest(['LOAD_DATA'], loadData);
}

export default function* dataStoredSagas() {
  yield all([fork(watchLoadData), fork(watchAddUser), fork(watchDeleteUser)]);
}
